import React from "react";
import cx from "classnames";

import TransitionLink from "../transition-link/transition-link";

interface Item {
  key?: string;
  link?: string;
  title?: string;
}

interface Props {
  className?: string;
  items?: Item[];
  current?: string;
}

export default function ({ className, items, current }: Props) {
  return (
    <nav
      className={cx(
        "breadcrumb text-sm px-5 lg:px-10 relative w-full",
        className
      )}
      aria-label="breadcrumb"
    >
      <ol
        className={cx("flex flex-wrap list-none")}
        itemScope
        itemType="https://schema.org/BreadcrumbList"
      >
        {items &&
          items.map(({ key, link, title }, index) => (
            <li
              className={cx("flex items-center p-0")}
              key={key}
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
            >
              <TransitionLink
                className={cx(
                  "font-semibold text-primary-red hover:text-red-accessible"
                )}
                to={link}
                itemProp="item"
              >
                <span itemProp="name">{title}</span>
              </TransitionLink>
              <span>
                <meta itemProp="position" content={`${index + 1}`} />
                {index < items.length - 1 ? (
                  <svg
                    className={cx("m-0 mx-3 -rotate-90")}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path d="M28.8 6.2l-12.8 12.8-12.8-12.8-3.2 3.2 16 16.4 16-16.4z" />
                  </svg>
                ) : (
                  ""
                )}
              </span>
            </li>
          ))}
        <li
          className="hidden"
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
          aria-current="page"
        >
          <span itemProp="name">{current}</span>
          <meta
            itemProp="position"
            content={`${items ? items.length + 1 : ""}`}
          />
        </li>
      </ol>
    </nav>
  );
}
