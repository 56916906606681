import React from "react";
import cx from "classnames";

import { URLS } from "../../config";
import HoverItem from "../hover-item/hover-item";
import { ImageProps } from "../../utils/types";
import Image from "../image/image";

interface Props {
  border?: boolean;
  links?: any;
  avatar?: {
    title?: string;
    picture?: ImageProps;
  };
  pretitle?: {
    target?: string;
    title?: string;
    url?: string;
  };
  subtitle?: string;
  title?: string;
  svg?: string;
  type?: string;
}

export default function Hero({
  border = true,
  links,
  avatar,
  pretitle,
  subtitle,
  title,
  svg,
  type,
}: Props) {

  if (type === "contributor") {
    return (
      <div id="hero" className={cx("relative hero", `hero--${type}`)}>
        <div className={cx("relative hero__wrapper")}>
          {avatar && avatar.picture ? (
            <div
              className="overflow-hidden mx-auto h-32 w-32"
            >
              <Image
                alt={avatar.title}
                className="block h-full w-full"
                gatsbyImageData={avatar.picture}
                borderRadius="100%"
              />
            </div>
          ) : (
            ""
          )}
          {title ? (
              <h1
                className={cx(
                  "text-black text-center leading-none font-normal mb-5 w-full hero__title--contributor"
                )}
                dangerouslySetInnerHTML={{ __html: title }}
              />
          ) : (
            ""
          )}
          <div className={cx("pb-5 sm:pb-10 lg:flex")}>
            {pretitle ? (
              pretitle && pretitle.url ? (
                <a
                  href={pretitle.url}
                  target={pretitle.target ? pretitle.target : "_parent"}
                  className={cx(
                    "block font-normal hover:text-red-accessible leading-none m-0 p-0 w-full md:w-1/3 hero__pretitle"
                  )}
                >
                  {pretitle.title}
                </a>
              ) : (
                <p
                  className={cx(
                    "block font-normal leading-none m-0 lg:mt-5 p-0 w-full md:w-1/3 hero__pretitle"
                  )}
                  dangerouslySetInnerHTML={{ __html: pretitle.title }}
                />
              )
            ) : (
              ""
            )}
            {subtitle ? (
              <p
                className={cx(
                  "font-light m-0 sm:mt-5 p-0 w-full md:w-2/3 hero__subtitle"
                )}
              >
                {subtitle}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="hero" className={cx("relative hero", `hero--${type}`)}>
      <div className={cx("relative hero__wrapper")}>
        {title ? (
          <div className="relative" >
            <h1
              className={cx(
                "relative z-10 text-black leading-none font-normal mb-5 w-full hero__title--default",
                {
                  "font-display italic": type === "homepage",
                }
              )}
            >{title}</h1>
            {svg ? (
              <img
                className={cx(
                  "hero__image absolute hidden lg:block z-0",
                  `hero__image--${svg}`
                )}
                src={`/svg/${svg}--light-red.svg`}
                alt={svg}
                loading="lazy"
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <div className={cx("relative pb-5 sm:pb-10 md:flex flex-wrap xl:justify-end")}>
          {pretitle ? (
            pretitle && pretitle.url ? (
              <a
                href={pretitle.url}
                target={pretitle.target ? pretitle.target : "_parent"}
                className={cx(
                  "block font-normal hover:text-red-accessible leading-none m-0 mb-2 lg:mb-0 p-0 w-full lg:w-1/3 hero__pretitle",
                  {
                    uppercase: type === "default",
                    "lg:w-1/3 ": links,
                    "lg:w-2/3 ": !links,
                  }
                )}
              >
                {pretitle.title}
              </a>
            ) : (
              <p
                className={cx(
                  "block font-normal leading-none m-0 mb-2 lg:mb-0 xl:mt-5 p-0 w-full lg:w-1/3 hero__pretitle",
                  {
                    uppercase: type === "default",
                    "lg:w-1/3 ": links,
                    "lg:w-2/3 ": !links,
                  }
                )}
                dangerouslySetInnerHTML={{ __html: pretitle.title }}
              />
            )
          ) : (
            ""
          )}
          {links ? (
            <ul
              className={cx(
                "font-light uppercase m-0 md:mt-6 p-0 leading-none block w-full md:w-1/2 lg:w-1/3 hero__links"
              )}
            >
              {links.map((link, i) => {
                const url = `${URLS.Archive}${link.continent.slug}/${link.slug}/`;
                return (
                  <HoverItem
                    key={i}
                    node={link}
                    url={url}
                    classNames={{
                      link: "block relative hover:text-red-accessible",
                      listItem: "relative",
                    }}
                  />
                );
              })}
            </ul>
          ) : (
            ""
          )}
          {subtitle ? (
            <p
              className={cx(
                "font-light m-0 mb-2 md:mb-0 md:mt-5 p-0 w-full md:w-1/2 lg:w-1/3 hero__subtitle"
              )}
            >
              {subtitle}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      {border ? (
        <hr
          className={cx(
            "border-0 border-t border-black relative h-px m-0 mx-auto w-0 page-break"
          )}
        />
      ) : (
        ""
      )}
    </div>
  );
}
