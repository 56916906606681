import React from "react";
import cx from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";

import TransitionLink from "../transition-link/transition-link";

import { URLS } from "../../config/urls";
import { Article } from "../../utils/types";

interface Props {
  article?: Article;
  header?: "h2" | "h3" | "h4";
  type?: string;
}

export default function ({ article, header, type = "all" }: Props) {
  const Tag: any = `${header}`;

  const limit = 200;
  let excerpt = article.excerpt?.childMarkdownRemark.html;

  if (excerpt && excerpt.length > limit) {
    excerpt = excerpt.substring(0, 200);
    excerpt = `${excerpt.substr(
      0,
      Math.min(excerpt.length, excerpt.lastIndexOf(" "))
    )}...`;
  }

  return (
    <div className={cx("text-black card")}>
      {article?.heroImage ? (
        <div className="card__wrapper mb-6">
          <TransitionLink
            data-cursor-type="read-journal"
            to={`${URLS.Archive}${article.country.continent.slug}/${article.country.slug}/${article.slug}/`}
          >
            <GatsbyImage
              className={cx("card__image aspect-video block")}
              alt={article.title || ""}
              image={article.heroImage.gatsbyImageData}
            />
          </TransitionLink>
        </div>
      ) : (
        ""
      )}
      <div>
        <ul
          className={cx("flex font-extralight p-0 mb-5 card__meta")}
        >
          {type === "country" ? (
            <li className={cx("flex flex-wrap mr-2.5")}>
              <TransitionLink
                className={cx("uppercase hover:text-primary-red")}
                to={`${URLS.Archive}${article.country.continent.slug}/`}
              >
                {article.country.continent.title}
              </TransitionLink>
            </li>
          ) : (
            ""
          )}
          {type === "continent" ? (
            <li className={cx("flex flex-wrap mr-2.5")}>
              <TransitionLink
                className={cx("uppercase hover:text-primary-red")}
                to={`${URLS.Archive}${article.country.continent.slug}/${article.country.slug}/`}
              >
                {article.country.title}
              </TransitionLink>
            </li>
          ) : (
            ""
          )}
          {type === "all" || type === "related" ? (
            <li className={cx("flex flex-wrap mr-2.5")}>
              <TransitionLink
                className={cx("uppercase hover:text-primary-red")}
                to={`${URLS.Archive}${article.country.continent.slug}/${article.country.slug}/`}
              >
                {article.country.title}
              </TransitionLink>
              ,&nbsp;
              <TransitionLink
                className={cx("uppercase hover:text-primary-red")}
                to={`${URLS.Archive}${article.country.continent.slug}/`}
              >
                {article.country.continent.title}
              </TransitionLink>
            </li>
          ) : (
            ""
          )}
          <li className={cx("flex mr-2.5")}>
            <div className={cx("mr-2.5")}>&mdash;</div>
            <TransitionLink
              className={cx("has-hover-underline hover:text-primary-red")}
              to={`${URLS.Contributor}${article.author.slug}/`}
            >
              by{" "}
              {article && article.author
                ? article.author.displayTitle ||
                  `${article.author.firstname} ${article.author.surname}`
                : ""}
            </TransitionLink>
          </li>
          <li className={cx("flex ml-auto mr-0")}>
            {new Date(article.publishDate).getFullYear()}
            {article.minuteRead ? (
              <span className="hidden xl:inline">
                &nbsp;&bull; {article.minuteRead} min read
              </span>
            ) : (
              ""
            )}
          </li>
        </ul>
        <TransitionLink
          to={`${URLS.Archive}${article.country.continent.slug}/${article.country.slug}/${article.slug}/`}
        >
          <Tag
            className={cx(
              "font-semibold leading-none mb-5 text-red-accessible hover:text-primary-red card__title"
            )}
          >
            {article.title}
          </Tag>
        </TransitionLink>
        <div
          className={cx("font-display font-thin text-sm lg:text-base")}
          dangerouslySetInnerHTML={{
            __html: excerpt,
          }}
        />
      </div>
    </div>
  );
}
