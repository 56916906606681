import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Layout from "../components/layout";
import ArticleList from "../components/article-list/article-list";
import Seo from "../components/seo/seo";
import Hero from "../components/hero/hero";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import { URLS } from "../config";
import ScrollSection from "../components/scroll-section/scroll-section";

class ContientPageTemplate extends React.Component {
  render() {
    const posts = get(this.props, "data.allContentfulPost.edges");
    const category = get(this.props, "data.contentfulAuthor");
    const continents = get(this, "props.data.allContentfulContinent.edges");
    const { firstname, shortBio, surname, twitter, picture, seoDescription } =
      category;

    return (
      <Layout
        classNames={{ container: "bg-red-lightest" }}
        continents={continents}
        location={this.props.location}
        status={this.props.transitionStatus}
      >
        <Seo
          type="page"
          content={{
            title: `${firstname} ${surname} Travel Stories`,
            seoDescription: `${seoDescription}`,
          }}
        />
        <ScrollSection>
          <Hero
            avatar={{
              title: `${firstname} ${surname}`,
              picture: picture,
            }}
            pretitle={{
              target: "_blank",
              title: `@${twitter}`,
              url: `https://www.twitter.com/${twitter}`,
            }}
            subtitle={shortBio}
            title={`${firstname} ${surname}`}
            type="contributor"
          />
        </ScrollSection>
        <ScrollSection>
          <ArticleList posts={posts} />
          <Breadcrumb
            className="mt-20 mb-2"
            items={[
              {
                key: "id_1",
                link: URLS.Contributor,
                title: "Contributors",
              },
            ]}
            current={`${firstname} ${surname}`}
          />
        </ScrollSection>
      </Layout>
    );
  }
}

export default ContientPageTemplate;

export const pageQuery = graphql`
  query ContributorQuery($id: String!) {
    contentfulAuthor(id: { eq: $id }) {
      displayTitle
      firstname
      shortBio
      surname
      twitter
      seoDescription
      picture {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          width: 424
          height: 212
        )
      }
    }
    allContentfulContinent(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          slug
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
        }
      }
    }
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      filter: { author: { id: { eq: $id } } }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate
          minuteRead
          heroImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
          excerpt {
            excerpt
            childMarkdownRemark {
              html
            }
          }
          author {
            displayTitle
            firstname
            surname
            location
            slug
          }
          country {
            slug
            title
            continent {
              title
              slug
            }
          }
        }
      }
    }
  }
`;
