import React from "react";
import cx from "classnames";

import ArticlePreview from "../article-preview/article-preview";
import { Posts } from "../../utils/types";

interface Props {
  header?: "h2" | "h3" | "h4";
  posts?: Posts[];
  type?: string;
}

export default function ({ header = "h2", posts, type }: Props) {
  return (
    <ul
      className={cx("relative flex flex-wrap list-none p-0 w-full z-10", {
        "px-5.5 md:px-2.75": posts.length > 1 && type !== "related",
        "md:-mx-2.75": posts.length > 1 && type === "related",
        "px-5.5": posts.length < 3 && type !== "related",
        "my-10": type !== "related",
      })}
    >
      {posts &&
        posts.map(({ node }) => (
          <li
            className={cx(`article-list__item mx-0 w-full `, {
              "mb-16 md:w-1/2 lg:w-1/3 md:px-2.75": posts.length > 2,
              "mb-16 md:mb-8 md:w-1/2 md:px-2.75": posts.length === 2,
              "lg:w-2/3": posts.length === 1,
            })}
            key={node.slug}
          >
            <ArticlePreview article={node} header={header} type={type} />
          </li>
        ))}
    </ul>
  );
}
